import bugsnag from 'bugsnag-js';

const {
  apiKey,
  appVersion,
  endpoint,
  ignorableUserAgents,
  releaseStage,
  user,
} = window.bugsnagConfig;

if (apiKey && endpoint) {
  window.bugsnagClient = bugsnag({
    ...(appVersion) && { appVersion },
    apiKey,
    releaseStage,
    endpoints: {
      notify: endpoint,
      sessions: endpoint,
    },
    user,
    beforeSend(report) {
      if (new RegExp(ignorableUserAgents.join('|')).test(report.device.userAgent)) {
        report.ignore();
      }
    },
  });
}
